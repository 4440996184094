import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { SurveyList, SurveyDelete } from "./survey-list";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};
@Injectable({
  providedIn: "root"
})
export class SurveyListService {
  /*  */
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("surveyListService");
  }

  /** GET heroes from the server */
  getSurveyList(surveyDetails: any): Observable<SurveyList[]> {
    return this.http
      .post<SurveyList[]>(api.baseUrl + api.survey.getSurveyList, surveyDetails)
      .pipe(catchError(this.handleError("getSurveyList", [])));
  }

  deleteSurvey(surveyDelete: SurveyList): Observable<SurveyList> {
    return this.http
      .put<SurveyList>(
        api.baseUrl + api.survey.deleteSurvey,
        { _id: surveyDelete._id },
        httpOptions
      )
      .pipe(catchError(this.handleError("updateHero", surveyDelete)));

    // return this.http.put(api.baseUrl + popValues.api, { _id: popValues._id });
  }

  duplicateSurvey(duplicateSurvey: SurveyList): Observable<SurveyList> {
    return this.http
      .post<SurveyList>(
        api.baseUrl + api.survey.duplicateSurvey,
        { _id: duplicateSurvey._id, survey_name: duplicateSurvey.survey_name },
        httpOptions
      )
      .pipe(catchError(this.handleError("updateHero", duplicateSurvey)));

    // return this.http.put(api.baseUrl + popValues.api, { _id: popValues._id });
  }
}
