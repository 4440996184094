import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { Logout } from "./navbar";
import { HttpErrorHandler, HandleError } from "../http-error-handler.service";
import { api } from "../environment/environment.api";
import { httpHeaders } from "../environment/environment.httpHeader";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};
@Injectable()
export class NavbarService {
  visible: boolean;

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }

  toggle() {
    this.visible = !this.visible;
  }

  // URL to web api
  private handleError: HandleError;

  /** POST: add a new hero to the database */
  postLogout(logout: Logout): Observable<Logout> {
    return (
      this.http
        .post<Logout>(api.baseUrl + api.user.logout, logout, httpOptions)
        //  .pipe(catchError(this.handleError("postLogin", login)));
        .pipe(catchError(this.handleError("logout", logout)))
    );
  }

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.hide();
    this.handleError = httpErrorHandler.createHandleError("NavbarService");
  }
}
