import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { StaffsList, StaffsDelete } from "./staffs-list";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable({
  providedIn: "root"
})
export class StaffsListService {
  /*  */
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("staffsListService");
  }

  /** GET heroes from the server */
  getStaffsList(staffDetails: any): Observable<StaffsList[]> {
    return this.http
      .post<StaffsList[]>(api.baseUrl + api.staffs.getStaffsList, staffDetails)
      .pipe(catchError(this.handleError("getStaffsList", [])));
  }

  deleteStaffs(staffsDelete: StaffsList): Observable<StaffsList> {
    return this.http
      .post<StaffsList>(
        api.baseUrl + api.staffs.deleteStaffs,
        { _id: staffsDelete._id },
        httpOptions
      )
      .pipe(catchError(this.handleError("updateHero", staffsDelete)));

    // return this.http.put(api.baseUrl + popValues.api, { _id: popValues._id });
  }
}
