export const api = {
  //baseUrl: "http://localhost:3000/",
  baseUrl: "https://smartsolution.dqslprojects.com/",
  common: {
    getTypesList: "types/list",
    getTypesCategory: "types/category"
  }, user: {
    login: "user/login",
    logout: "user/logout"
  },
  dashboard: {
    getCounts: "dashboard/counts",
    getActiveClasses: "dashboard/activeAttendance",
    getStaffCounts: "dashboard/staffCounts",
    getLatestSurvey: "dashboard/latestSurvey",
  },
  survey: {
    getQuestionTypes: "type/list",
    getSurveyList: "surveyQus/list",
    deleteSurvey: "surveyQus/bin",
    duplicateSurvey: "surveyQus/duplicate",
    getSurveyById: "surveyQus/surveyById",
    createSurvey: "surveyQus/create",
    updateSurvey: "surveyQus/update",
    getLanguageList: "language/list"
  },
  responses: {
    getResponseList: "surveyRes/list",
    getResponseById: "surveyRes/responseById"
  },
  course: {
    getCourseList: "course/list",
    getCourseListAll: "course/listAll",
    getCourseById: "course/courseById",
    createCourse: "course/create",
    updateCourse: "course/update",
    deleteCourse: "course/delete",
    getCourseTypes: "type/list"
  },
  class: {
    getClassList: "class/list",
    createClass: "class/create",
    deleteClass: "class/delete",
    getClassById: "class/classById",
    updateClass: "class/update",
  },
  staffs: {
    getStaffsList: "staffs/list",
    createStaffs: "staffs/create",
    deleteStaffs: "staffs/delete",
    getStaffById: "staffs/staffById",
    updateStaffs: "staffs/update",
  },
  attendance: {
    getAttendanceList: "",
    deleteAttendance: "attendance/delete",
    getAttendanceById: "attendance/detailsById",
    updateAttendance: "attendance/update",
    getAttendanceByDate: "attendance/detailsByDate",
    saveAttendance: "attendance/create",
  }
};
