import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { ResponseDetails } from "./view-response";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable({
  providedIn: 'root'
})
export class ViewResponseService {
  /*  */
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("ResponseDetailsService");
  }

  /** GET heroes from the server */ 
  getResponseDetails(params: any): Observable<ResponseDetails[]> {
    return this.http
      .post<ResponseDetails[]>(api.baseUrl + api.responses.getResponseById, {
        _id: params.id,
        user_id: "main user"
      })
      .pipe(catchError(this.handleError("getResponseDetails", [])));
  }
}
