import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { StaffsRouteParams, StaffsDetails } from "./create-staffs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};
@Injectable({
  providedIn: "root"
})
export class CreateStaffsService {
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("staffsListService");
  }

  /** GET heroes from the server */
  getStaffsById(staffsId: any): Observable<StaffsRouteParams[]> {
    return this.http
      .post<StaffsRouteParams[]>(api.baseUrl + api.staffs.getStaffById, {
        _id: staffsId
      })
      .pipe(catchError(this.handleError("getStaffsById", [])));
  }
  /** GET heroes from the server */
  getStaffsTypes(category: any): Observable<StaffsDetails[]> {
    return this.http
      .post<StaffsDetails[]>(api.baseUrl + api.common.getTypesCategory, category)
      .pipe(catchError(this.handleError("getStaffsTypes", [])));
  }

  /** Create heroes from the server */
  createStaffs(staffsDetails: any): Observable<StaffsDetails[]> {
    return this.http
      .post<StaffsDetails[]>(api.baseUrl + api.staffs.createStaffs, staffsDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }

  /** Update heroes from the server */
  updateStaffs(staffsDetails: any): Observable<StaffsDetails[]> {
    return this.http
      .post<StaffsDetails[]>(api.baseUrl + api.staffs.updateStaffs, staffsDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }
}
