import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { SurveyList, SurveyDelete } from "./survey-list";
import { SurveyListService } from "./survey-list.service";
import { DashboardCounts } from "./../../dashboard/dashboard";
import { DashboardService } from "./../../dashboard/dashboard.service";

declare let swal: any;

@Component({
  selector: "app-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.css"]
})
export class SurveyListComponent implements OnInit {
  surveyList: SurveyList[] = [];
  dashboardDetails: [];
  dashboardCounts: any;
  surveyDelete = new SurveyDelete("", "", "");
  surveyDetails: any;
  surveyCount: any;

  constructor(
    public navbarService: NavbarService,
    private surveyListService: SurveyListService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private dashboardService: DashboardService
  ) {
    this.sidebarService.show();
    this.surveyDelete = new SurveyDelete(
      "Delete ",
      "Are you sure want to delete this survey?",
      "Survey deleted"
    );
    this.surveyDetails = {
      user_id: "5c802408c6a0e0136468f5ff",
      status: "active"
    }
    this.surveyCount = {
      "active": 0,
      "total": 0
    }
  }

  ngOnInit() {
    this.loaderService.show();
    this.navbarService.show();
    this.fnGetSurveyList();
    this.fnGetResponseList();
  }

  fnGetSurveyList(): void {
    this.surveyListService.getSurveyList(this.surveyDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.surveyList = response.data.sort((a, b) => {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        });
        this.surveyCount.total = this.surveyList.length;
        this.surveyList.forEach(element => {
          if (element["status"] == "active") {
            this.surveyCount.active++;
          }
        });
      }
      this.loaderService.hide();
    });
  }

  fnDeleteSurvey(surveyId): void {
    var moment = this;
    const tempSurveyId: SurveyList = {
      _id: surveyId
    } as SurveyList;
    swal(
      {
        title: this.surveyDelete.title,
        text: this.surveyDelete.text,
        response: this.surveyDelete.response,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function () {
        moment.fnDeleteSurveyCall(tempSurveyId);
      }
    );
  }

  fnDeleteSurveyCall(tempSurveyId): void {
    this.surveyListService
      .deleteSurvey(tempSurveyId)
      .subscribe((response: any) => {
        this.fnGetSurveyList();
        swal(this.surveyDelete.response);
      });
  }

  fnDuplicateSurvey(surveyId): void {
    var moment = this;
    const tempSurveyId: SurveyList = {
      _id: surveyId,
      survey_name: ""
    } as SurveyList;

    swal(
      {
        title: "Duplicate Survey",
        text: "Please enter the new survey name",
        type: "input",
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
        inputPlaceholder: "New Survey Name"
      },
      function (inputValue) {
        if (inputValue === false) {
          return false;
        } else if (inputValue === "") {
          swal.showInputError("Please write the survey name");
          return false;
        } else {
          tempSurveyId.survey_name = inputValue;
          moment.fnDuplicateSurveyCall(tempSurveyId);
        }
      }
    );
  }

  fnDuplicateSurveyCall(tempSurveyId): void {
    this.surveyListService
      .duplicateSurvey(tempSurveyId)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.fnGetSurveyList();
          swal(
            "Survey Duplicated",
            "Survey created with the name " + response.data.survey_name, "success"
          );
        } else if (response.code == 409) {
          swal("Survey Not Created!", "Survey name already exists. Try again with a new name.", "warning");
        } else {
          // swal.showInputError("Internal Error. Please Try Again After Sometime.");
          swal("Survey Not Created!", "Internal Error. Please Try Again After Sometime.", "warning");
        }
      });
  }

  fnGetResponseList(): void {
    this.dashboardService.getDashboardCount(this.dashboardDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.dashboardCounts = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnGotoView(routParams: any, type: string): void {
    this.loaderService.show();
    let tempRoutParams = []
    tempRoutParams["_id"] = routParams._id;
    tempRoutParams["type"] = type;
    this.router.navigate(["/create-survey", tempRoutParams]);
  }
}
