import { Component, OnInit } from '@angular/core';

import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { StaffsList, StaffsDelete } from "./staffs-list";
import { StaffsListService } from "./staffs-list.service";

declare let swal: any;

@Component({
  selector: 'app-staffs-list',
  templateUrl: './staffs-list.component.html',
  styleUrls: ['./staffs-list.component.css']
})
export class StaffsListComponent implements OnInit {
  staffsList: StaffsList[] = [];
  staffsDelete = new StaffsDelete("", "", "");
  staffDetails: any;

  constructor(
    public navbarService: NavbarService,
    private staffsListService: StaffsListService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
  ) {
    this.sidebarService.show();
    this.staffsDelete = new StaffsDelete(
      "Delete ",
      "Are you sure want to delete this staffs?",
      "Staffs deleted"
    );
    this.staffDetails = {
      user_id: "5c802408c6a0e0136468f5ff",
      status: "active"
    }
  }

  ngOnInit() {
    this.loaderService.show();
    this.navbarService.show();
    this.fnGetStaffsList();
  }

  fnGetStaffsList(): void {
    this.staffsListService.getStaffsList(this.staffDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.staffsList = response.data.sort((a, b) => {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        });
      }
      this.loaderService.hide();
    });
  }

  fnDeleteStaffs(staffsId): void {
    var moment = this;
    const tempStaffsId: StaffsList = {
      _id: staffsId
    } as StaffsList;
    swal(
      {
        title: this.staffsDelete.title,
        text: this.staffsDelete.text,
        response: this.staffsDelete.response,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function () {
        moment.fnDeleteStaffsCall(tempStaffsId);
      }
    );
  }

  fnDeleteStaffsCall(tempStaffsId): void {
    this.staffsListService
      .deleteStaffs(tempStaffsId)
      .subscribe((response: any) => {
        this.fnGetStaffsList();
        swal(this.staffsDelete.response);
      });
  }

  fnGotoView(routParams: any, type: string): void {
    this.loaderService.show();
    routParams["type"] = type;
    this.router.navigate(["/create-staffs", routParams]);
  }
}
