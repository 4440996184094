export interface SurveyList {
  _id: string;
  survey_name: string;
}

export class SurveyDelete {
  constructor(
    public title: string,
    public text: string,
    public response: string
  ) {}
}
