import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";
import { SortablejsOptions } from "angular-sortablejs";

import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { SurveyRouteParams, SurveyDetails } from "./create-survey";
import { CreateSurveyService } from "./create-survey.service";
import { element } from 'protractor';

declare let swal: any;

@Component({
  selector: "app-create-survey",
  templateUrl: "./create-survey.component.html",
  styleUrls: ["./create-survey.component.css"]
})
export class CreateSurveyComponent implements OnInit {
  // surveyRouteParams: SurveyRouteParams;
  surveyDetails: any;
  surveyRouteParams: any;
  questionTypes: any;
  languageList: any;
  surveyLanguages: any;
  surveyType: string;
  flagLanguageList: boolean;
  flagSurveyList: boolean;
  flagLanguageEnable: boolean;

  constructor(
    private router: Router,
    private createSurveyService: CreateSurveyService,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private route: ActivatedRoute) {
    this.sidebarService.show();
    //   const navigation = this.router.getCurrentNavigation();
    //  this.surveyRouteParams = navigation.extras;
    this.route.paramMap.subscribe(params => {
      this.surveyRouteParams = params["params"];
      this.surveyType = this.surveyRouteParams["type"];
    });
    this.surveyLanguages = [{ "name": "English", "direction": "ltr" }];
    this.surveyDetails = {
      _id: "",
      survey_name: "",
      user_id: "",
      created_date: new Date(),
      status: "",
      type: this.surveyType,
      language: [{
        name: "",
        direction: ""
      }],
      questions: [{
        details: [{
          language: "English",
          direction: "ltr",
          _id: "",
          title: "",
          is_required: false,
          value: "",
          question_type: 0,
          order: 0,
          placeholder: "",
          titleLeft: "",
          titleRight: "",
          minLength: 10,
          maxLength: 50,
          fields: [{
            qusType: "",
            title: "",
            mandatory: false
          }],
          choices: [{
            title: ""
          }]
        }]
      }]
    };
    this.flagLanguageList = false;
    this.flagSurveyList = false;
  }

  fnGetSurveyById(surveyId: string): void {
    this.createSurveyService
      .getSurveyById(surveyId)
      .subscribe((response: any) => {
        if (response.code == 200) {
          let tempSurveyDetail = response.data;
          /*   tempSurveyDetail.questions.forEach(element1 => {
              let i = 0;
              element1.questions.forEach(element2 => {
                let details = [];
                details.push(element1[i])
                this.surveyDetails.questions.push({ "details": details });
              });
              i++;
            }); */

          localStorage.setItem("tempSaveSurveyDetail", JSON.stringify(tempSurveyDetail));

          this.surveyDetails = JSON.parse(localStorage.getItem("tempSaveSurveyDetail"));
          this.surveyDetails.questions = [];
          localStorage.removeItem("tempSaveSurveyDetail");

          if (tempSurveyDetail.questions) {
            let x = tempSurveyDetail.questions.length;
            for (let i = 0; i < tempSurveyDetail.questions[0].questions.length; i++) {
              let details = [];
              for (let j = 0; j < x; j++) {
                tempSurveyDetail.questions[j].questions[i]["language"] = tempSurveyDetail.questions[j].language;
                tempSurveyDetail.questions[j].questions[i]["direction"] = tempSurveyDetail.questions[j].direction;
                details.push(tempSurveyDetail.questions[j].questions[i])
              }
              this.surveyDetails.questions.push({ "details": details });
            }
          }
          this.fnMatchCardHeight();
          this.flagSurveyList = true;
          this.fnSetLanguageList();
        }
        this.loaderService.hide();
      });
  }

  fnGetQuestionTypes(): void {
    this.createSurveyService.getQuestionTypes().subscribe((response: any) => {
      if (response.code == 200) {
        this.questionTypes = response.data;
      }
      this.loaderService.hide();
    });
  }

  fnGetLanguageList(): void {
    this.createSurveyService.getLanguageList().subscribe((response: any) => {
      if (response.code == 200) {
        this.languageList = response.data;
        this.languageList.forEach(element => {
          if (element.name == 'English') {
            element["checkFlag"] = true;
            delete element["_id"];
            delete element["user_id"];
            delete element["created_date"];
            delete element["status"];
          }
        });
        this.flagLanguageList = true;
        this.fnSetLanguageList();
        this.checkLanguageList();
      }
    });
  }

  fnSetLanguageList(): void {
    if (this.flagLanguageList && this.flagSurveyList) {
      this.languageList.forEach(element1 => {
        element1["checkFlag"] = false;
        this.surveyDetails.language.forEach(element2 => {
          if (element1.name == element2.language) {
            element1["checkFlag"] = true;
          }
        });
      });
    }
  }

  fnAddLanguage(lang: any, indexVal: number): void {
    /* if (lang.checkFlag) {
      if (lang.name == 'English') {
        this.surveyLanguages.unshift({
          "name": lang.name,
          "direction": lang.direction
        });
      } else {
        this.surveyLanguages.push({
          "name": lang.name,
          "direction": lang.direction
        });
      }
    } else {
      this.surveyLanguages = this.surveyLanguages.filter(element => { return element.name != lang.name });
    }
    this.surveyDetails.language = this.surveyLanguages; */

    if (lang.checkFlag) {
      this.surveyDetails.questions.forEach(element => {
        localStorage.setItem("tempElement", JSON.stringify(element.details[0]))
        let tempElement = JSON.parse(localStorage.getItem("tempElement"));
        tempElement.language = lang.name;
        tempElement.direction = lang.direction;
        element.details.push(tempElement);
        localStorage.removeItem("tempElement");
      });
    } else {
      this.surveyDetails.questions.forEach(element1 => {
        element1.details.forEach((element2, index, object) => {
          if (element2.language == lang.name) {
            object.splice(index, 1);
          }
        });

      });
    }
    this.fnMatchCardHeight();
    this.checkLanguageList();
  }

  checkLanguageList(): void {
    let i = 0
    this.languageList.forEach(element => {
      if (element.checkFlag) {
        i++;
      }
    });
    if (i <= 1) {
      this.flagLanguageEnable = true;
    } else {
      this.flagLanguageEnable = false;
    }
  }

  fnChangeDirection(lang: any): void {
    this.surveyLanguages.forEach(element => {
      if (element.name == lang.name) {
        element["direction"] = lang.direction
      }
    });

  }

  fnAddQuestion(): void {
    let details = [];
    this.languageList.forEach(element => {
      if (element.checkFlag) {
        details.push(
          {
            language: element.name,
            direction: element.direction,
            _id: "",
            title: "",
            is_required: false,
            value: "",
            question_type: 0,
            order: 0,
            placeholder: "",
            titleLeft: "",
            titleRight: "",
            minLength: 10,
            maxLength: 50,
            fields: [{
              qusType: "",
              title: "",
              mandatory: false
            }],
            choices: [{
              title: ""
            }]
          }
        )
      }
    });
    this.surveyDetails.questions.push({ "details": details });

  }

  fnSaveSurvey(): void {

    this.loaderService.show();
    this.surveyDetails.language = this.languageList;
    this.surveyDetails.type = this.surveyType;
    let indexI = 1;
    this.surveyDetails.questions.forEach(element => {
      element.details[0].order = indexI;
      indexI++;
    });

    let tempQuestion = [];

    this.surveyDetails.language.forEach(element => {
      delete element.__v;

    });

    this.surveyDetails.questions[0].details.forEach(element2 => {
      tempQuestion.push({
        "language": element2.language,
        "direction": element2.direction,
        "questions": []
      })
    });

    this.surveyDetails.questions.forEach(element1 => {
      element1.details.forEach(element2 => {
        tempQuestion.forEach(element3 => {
          if (element3.language == element2.language) {
            element3.questions.push(element2)
          }
        });
      });
    });

    let tempSurveyDetails: any = {
      "user_id": this.surveyDetails.user_id,
      "_id": this.surveyDetails._id,
      "survey_name": this.surveyDetails.survey_name,
      "status": this.surveyDetails.status,
      "type": this.surveyDetails.type,
      "language": [],
      "questions": tempQuestion,
    };

    tempSurveyDetails.questions.forEach(element => {
      tempSurveyDetails.language.push({
        "language": element.language,
        "direction": element.direction
      })
    });

    if (this.surveyType == "edit") {
      this.createSurveyService
        .updateSurvey(tempSurveyDetails)
        .subscribe((response: any) => {
          this.loaderService.hide();
          if (response.code == 200) {
            this.fnGetSurveyById(response.data._id);
            swal("Survey Updated!", "", "success");
          } else if (response.code == 409) {
            swal("Survey Not Created!", "Survey name already exists. Try again with a new name.", "warning");
          }
        });
    } else {
      this.createSurveyService
        .createSurvey(tempSurveyDetails)
        .subscribe((response: any) => {
          this.loaderService.hide();
          if (response.code == 200) {
            this.fnGetSurveyById(response.data._id);
            swal("Survey Created!", "", "success");
            this.surveyType = "edit";
          } else if (response.code == 409) {
            swal("Survey Not Created!", "Survey name already exists. Try again with a new name.", "warning");
          }
        });
    }
  }

  fnChangeQuestionType(type: number, indexValue: number): void {
    this.surveyDetails.questions[indexValue].details.forEach(element => {
      element.question_type = type;
    });
  }

  fnMatchCardHeight(): void {
    this.loaderService.show();
    setTimeout(() => {
      const elemQues = document.getElementsByClassName('survey-ques-card');
      const elemAns = document.getElementsByClassName('survey-ans-card');
      for (let iQ = 0; iQ < elemQues.length; iQ++) {
        const tempElemQues = elemQues[iQ] as HTMLElement;
        const tempElemAns = elemAns[iQ] as HTMLElement;
        if (tempElemQues.offsetHeight > tempElemAns.offsetHeight) {
          tempElemAns.style.height = tempElemQues.offsetHeight + "px";
        } else {
          tempElemQues.style.height = tempElemAns.offsetHeight + "px";
        }
      }
      this.loaderService.hide();
    }, 3000);
  }

  ngAfterViewInit() {
    this.fnMatchCardHeight();
  }


  ngOnInit() {
    this.loaderService.show();
    this.fnGetLanguageList();
    this.fnGetQuestionTypes();
    if (this.surveyType == "edit") {
      this.fnGetSurveyById(this.surveyRouteParams._id);
    }
  }
}
