import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { ClassList, ClassDelete } from "./class-list";
import { ClassListService } from "./class-list.service";
import { DashboardService } from "./../../dashboard/dashboard.service";

declare let swal: any;

@Component({
  selector: "app-class-list",
  templateUrl: "./class-list.component.html",
  styleUrls: ["./class-list.component.css"]
})
export class ClassListComponent implements OnInit {
  classList: ClassList[] = [];
  classDelete = new ClassDelete("", "", "");
  classListFlag: boolean;
  activeAtendance: any;

  constructor(
    public navbarService: NavbarService,
    private classListService: ClassListService,
    private dashboardService: DashboardService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
  ) {
    this.classListFlag = false;
    this.sidebarService.show();
    this.classDelete = new ClassDelete(
      "Delete ",
      "Are you sure want to delete this class?",
      "Class deleted"
    );
  }

  ngOnInit() {
    this.loaderService.show();
    this.navbarService.show();
    this.fnGetClassList();
    this.fnGetActiveClasses();
  }

  fnGetClassList(): void {
    this.classListService.getClassList().subscribe((response: any) => {
      if (response.code == 200) {
        this.classList = response.data.sort((a, b) => {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        });
        this.loaderService.hide();
      }
      this.classListFlag = true;
    });
  }

  fnGetActiveClasses(): void {
    this.loaderService.show();
    this.dashboardService.getActiveClasses(this.activeAtendance).subscribe((response: any) => {
      if (response.code == 200) {
        this.activeAtendance = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnDeleteClass(classId): void {
    var moment = this;
    const tempClassId: ClassList = {
      _id: classId
    } as ClassList;
    swal(
      {
        title: this.classDelete.title,
        text: this.classDelete.text,
        response: this.classDelete.response,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function () {
        moment.fnDeleteClassCall(tempClassId);
      }
    );
  }

  fnDeleteClassCall(tempClassId): void {
    this.classListService
      .deleteClass(tempClassId)
      .subscribe((response: any) => {
        this.fnGetClassList();
        swal(this.classDelete.response);
      });
  }

  fnGotoView(id: string, view: string, type: string): void {
    this.loaderService.show();
    if (view == 'class') {
      view = 'create-class';
    } else if (view == 'course') {
      view = 'create-course';
    }
    let tempRouteParams: any = {
      "_id": id,
      "type": type
    };
    this.router.navigate([view, tempRouteParams]);
  }
}
