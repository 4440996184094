export interface AttendanceList {
    _id: string;
    survey_name: string;
}

export class AttendanceDelete {
    constructor(
        public title: string,
        public text: string,
        public response: string
    ) { }
}
