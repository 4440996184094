import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { CourseRouteParams, CourseDetails } from "./create-course";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};
@Injectable({
  providedIn: "root"
})
export class CreateCourseService {
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("courseListService");
  }

  /** GET heroes from the server */
  getCourseById(courseId: string): Observable<CourseRouteParams[]> {
    return this.http
      .post<CourseRouteParams[]>(api.baseUrl + api.course.getCourseById, {
        _id: courseId
      })
      .pipe(catchError(this.handleError("getCourseById", [])));
  }
  /** GET heroes from the server */
  getCourseTypes(): Observable<CourseDetails[]> {
    return this.http
      .get<CourseDetails[]>(api.baseUrl + api.course.getCourseTypes, {})
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }

  /** Create heroes from the server */
  createCourse(courseDetails: any): Observable<CourseDetails[]> {
    return this.http
      .post<CourseDetails[]>(api.baseUrl + api.course.createCourse, courseDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }

  /** Update heroes from the server */
  updateCourse(courseDetails: any): Observable<CourseDetails[]> {
    return this.http
      .post<CourseDetails[]>(api.baseUrl + api.course.updateCourse, courseDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }
}
