import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { CourseList, CourseDelete } from "./course-list";
import { CourseListService } from "./course-list.service";

declare let swal: any;

@Component({
  selector: "app-course-list",
  templateUrl: "./course-list.component.html",
  styleUrls: ["./course-list.component.css"]
})
export class CourseListComponent implements OnInit {
  courseList: CourseList[] = [];
  courseDelete = new CourseDelete("", "", "");
  courseDetails: any;

  constructor(
    public navbarService: NavbarService,
    private courseListService: CourseListService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
  ) {
    this.sidebarService.show();
    this.courseDelete = new CourseDelete(
      "Delete ",
      "Are you sure want to delete this course?",
      "Course deleted"
    );
    this.courseDetails = {
      user_id: "5c802408c6a0e0136468f5ff",
      status: "active"
    }
  }

  ngOnInit() {
    this.loaderService.show();
    this.navbarService.show();
    this.fnGetCourseList();
  }

  fnGetCourseList(): void {
    this.courseListService.getCourseList(this.courseDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.courseList = response.data.sort((a, b) => {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        });
        this.loaderService.hide();
      }
    });
  }

  fnDeleteCourse(courseId): void {
    var moment = this;
    const tempCourseId: CourseList = {
      _id: courseId
    } as CourseList;
    swal(
      {
        title: this.courseDelete.title,
        text: this.courseDelete.text,
        response: this.courseDelete.response,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function () {
        moment.fnDeleteCourseCall(tempCourseId);
      }
    );
  }

  fnDeleteCourseCall(tempCourseId): void {
    this.courseListService
      .deleteCourse(tempCourseId)
      .subscribe((response: any) => {
        this.fnGetCourseList();
        swal(this.courseDelete.response);
      });
  }

  fnGotoView(id: any, type: string): void {
    this.loaderService.show();
    let routParams: any = {
      "type": type,
      "_id": id
    }
    this.router.navigate(["/create-course", routParams]);
  }
}
