import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { SortablejsModule } from "angular-sortablejs";
import { OrderModule } from "ngx-order-pipe";
import { MomentModule } from 'ngx-moment';

import { NgbModule, NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { NavbarService } from "./navbar/navbar.service";
import { HttpErrorHandler } from "./http-error-handler.service";
import { MessageService } from "./message.service";
import { ExportAsModule } from 'ngx-export-as';
import { ChartsModule } from 'ng2-charts';
import { NgxPrintModule } from 'ngx-print';

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { LandingComponent } from "./landing/landing.component";
import { ConfigComponent } from "./config/config.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SurveyListComponent } from "./survey/survey-list/survey-list.component";
import { CreateSurveyComponent } from "./survey/create-survey/create-survey.component";
import { PopupComponent } from "./common/popup/popup.component";
import { PopupService } from "./common/popup/popup.service";
import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { SidebarService } from "./common/sidebar/sidebar.service";
import { CourseListComponent } from "./course/course-list/course-list.component";
import { CreateCourseComponent } from "./course/create-course/create-course.component";
import { ClassListComponent } from "./class/class-list/class-list.component";
import { CreateClassComponent } from "./class/create-class/create-class.component";
import { StaffsListComponent } from './staffs/staffs-list/staffs-list.component';
import { CreateStaffsComponent } from './staffs/create-staffs/create-staffs.component';
import { FilterPipe } from './common/filterPipes.pipe';
import { AttendanceListComponent } from './attendance/attendance-list/attendance-list.component';
import { ViewAttendanceComponent } from './attendance/view-attendance/view-attendance.component';
import { ResponseListComponent } from './response/response-list/response-list.component';
import { ViewResponseComponent } from './response/view-response/view-response.component';
import { LoaderComponent } from './common/loader/loader.component';
import { AddAttendanceComponent } from './attendance/add-attendance/add-attendance.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    LandingComponent,
    ConfigComponent,
    DashboardComponent,
    SurveyListComponent,
    CreateSurveyComponent,
    PopupComponent,
    SidebarComponent,
    CourseListComponent,
    CreateCourseComponent,
    ClassListComponent,
    CreateClassComponent,
    StaffsListComponent,
    CreateStaffsComponent,
    FilterPipe,
    AttendanceListComponent,
    ViewAttendanceComponent,
    ResponseListComponent,
    ViewResponseComponent,
    LoaderComponent,
    AddAttendanceComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    NgbAlertModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SortablejsModule,
    OrderModule,
    ExportAsModule,
    ChartsModule,
    NgxPrintModule,
    MomentModule
  ],
  providers: [
    NavbarService,
    HttpErrorHandler,
    MessageService,
    PopupService,
    SidebarService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
