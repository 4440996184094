import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { ClassList, ClassDelete } from "./class-list";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable({
  providedIn: "root"
})
export class ClassListService {
  /*  */
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("classListService");
  }

  /** GET heroes from the server */
  getClassList(): Observable<ClassList[]> {
    return this.http
      .get<ClassList[]>(api.baseUrl + api.class.getClassList)
      .pipe(catchError(this.handleError("getClassList", [])));
  }

  deleteClass(classDelete: ClassList): Observable<ClassList> {
    return this.http
      .post<ClassList>(
        api.baseUrl + api.class.deleteClass,
        { _id: classDelete._id },
        httpOptions
      )
      .pipe(catchError(this.handleError("updateHero", classDelete)));

    // return this.http.put(api.baseUrl + popValues.api, { _id: popValues._id });
  }
}
