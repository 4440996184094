import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { ClassRouteParams, ClassDetails } from "./create-class";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};
@Injectable({
  providedIn: "root"
})
export class CreateClassService {
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("classListService");
  }

  /** GET heroes from the server */
  getClassById(classId: string): Observable<ClassRouteParams[]> {
    return this.http
      .post<ClassRouteParams[]>(api.baseUrl + api.class.getClassById, {
        class_id: classId
      })
      .pipe(catchError(this.handleError("getClassById", [])));
  }
  /** GET heroes from the server */
  getClassTypes(category: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.common.getTypesCategory, category)
      .pipe(catchError(this.handleError("getClassTypes", [])));
  }

  /** Create heroes from the server */
  createClass(classDetails: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.class.createClass, classDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }

  /** Update heroes from the server */
  updateClass(classDetails: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.class.updateClass, classDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }

  /** GET heroes from the server */
  getCourseList(params: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.course.getCourseList, params)
      .pipe(catchError(this.handleError("getCourseList", [])));
  }

  /** GET heroes from the server */
  getStaffsList(params: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.staffs.getStaffsList, params)
      .pipe(catchError(this.handleError("getStaffsList", [])));
  }


}
