import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { SurveyRouteParams, SurveyDetails } from "./create-survey";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};
@Injectable({
  providedIn: "root"
})
export class CreateSurveyService {
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("surveyListService");
  }

  /** GET heroes from the server */
  getSurveyById(surveyId: string): Observable<SurveyRouteParams[]> {
    return this.http
      .post<SurveyRouteParams[]>(api.baseUrl + api.survey.getSurveyById, {
        _id: surveyId
      })
      .pipe(catchError(this.handleError("getSurveyById", [])));
  }
  /** GET heroes from the server */
  getQuestionTypes(): Observable<SurveyDetails[]> {
    return this.http
      .get<SurveyDetails[]>(api.baseUrl + api.survey.getQuestionTypes, {})
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }

  /** Get Language list from the server */
  getLanguageList(): Observable<SurveyDetails[]> {
    return this.http
      .post<SurveyDetails[]>(api.baseUrl + api.survey.getLanguageList, {})
      .pipe(catchError(this.handleError("getLanguageList", [])));
  }

  /** Create heroes from the server */
  createSurvey(surveyDetails: any): Observable<SurveyDetails[]> {
    return this.http
      .post<SurveyDetails[]>(api.baseUrl + api.survey.createSurvey, surveyDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }

  /** Update heroes from the server */
  updateSurvey(surveyDetails: any): Observable<SurveyDetails[]> {
    return this.http
      .post<SurveyDetails[]>(api.baseUrl + api.survey.updateSurvey, surveyDetails)
      .pipe(catchError(this.handleError("getQuestionTypes", [])));
  }
}
