export interface AttendanceDetails {
    _id: string;
    attendance_name: string;
}
export interface ClassDetails {
    _id: string;
    class_name: string;
}

export class AttendanceDelete {
    constructor(
        public title: string,
        public text: string,
        public response: string
    ) { }
}
