import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { CourseRouteParams, CourseDetails } from "./create-course";
import { CreateCourseService } from "./create-course.service";

declare let swal: any;

@Component({
  selector: "app-create-course",
  templateUrl: "./create-course.component.html",
  styleUrls: ["./create-course.component.css"]
})
export class CreateCourseComponent implements OnInit {
  // courseRouteParams: CourseRouteParams;
  courseDetails: CourseDetails;
  courseRouteParams: any;
  questionTypes: any;

  constructor(
    private router: Router, private route: ActivatedRoute,
    private createCourseService: CreateCourseService,
    public sidebarService: SidebarService,
    public loaderService: LoaderService
  ) {
    this.sidebarService.show();
    this.route.paramMap.subscribe(params => {
      this.courseRouteParams = params["params"];
    });

    this.courseDetails = {
      _id: "",
      course_id: "",
      course_name: "",
      user_id: "",
      created_date: new Date(),
      status: "",
      type: this.courseRouteParams.type,
      course_description: ""
    };
  }

  fnGetCourseById(courseId: string): void {
    this.createCourseService
      .getCourseById(courseId)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.courseDetails = response.data;
          this.loaderService.hide();
        }
      });
  }

  fnGetCourseTypes(): void {
    this.createCourseService.getCourseTypes().subscribe((response: any) => {
      if (response.code == 200) {
        this.questionTypes = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnSaveCourse(): void {
    this.loaderService.show();
    this.courseDetails.type = this.courseRouteParams.type;
    if (this.courseRouteParams.type == "edit") {
      this.createCourseService
        .updateCourse(this.courseDetails)
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.courseDetails = response.data;
            this.loaderService.hide();
            swal("Course Updated!", "", "success");
          }
        });
    } else {
      this.createCourseService
        .createCourse(this.courseDetails)
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.courseDetails = response.data;
            this.courseDetails.type = "edit";
            this.courseRouteParams = {
              "_id": this.courseDetails._id,
              "type": "edit"
            };
            this.loaderService.hide();
            swal("Course Created!", "", "success");

          }
        });
    }
  }

  fnCancelCourse(): void {

  }

  ngOnInit() {
    this.loaderService.show();
    /* this.courseRouteParams = {
      _id: "5ca0db674993bd3020e2bde5",
      course_name: "Test Course 12",
      user_id: "5c802408c6a0e0136468f5ff",
      created_date: new Date("2019-03-22T21:40:41.016Z"),
      totalQuestions: 2,
      type: "edit"
    }; */
    this.fnGetCourseTypes();
    if (this.courseRouteParams.type == "edit") {
      this.fnGetCourseById(this.courseRouteParams._id);
    }
  }
}
