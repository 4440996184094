import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { Logout, LogoutValues } from "./navbar";
import { NavbarService } from "./navbar.service";

declare let swal: any;

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit {
  constructor(public navbarService: NavbarService, private router: Router) { }

  ngOnInit() {
    this.navbarService.show();
  }

  logout(): void {
    localStorage.setItem('currentView', "");
    const newLogoutParams: Logout = {} as Logout;
    this.navbarService
      .postLogout(newLogoutParams)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.router.navigate([""]);
        }
      });
  }
}
