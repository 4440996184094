import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SidebarService {
  sidebarVisible: boolean;

  hide() {
    this.sidebarVisible = false;
  }

  show() {
    this.sidebarVisible = true;
  }

  toggle() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  constructor() {
    this.hide();
  }
}
