import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { Login } from "./login";
import { HttpErrorHandler, HandleError } from "../http-error-handler.service";
import { api } from "../environment/environment.api";
import { httpHeaders } from "../environment/environment.httpHeader";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable({
  providedIn: "root"
})
export class LoginService {
  /*  */
  private handleError: HandleError;

  /** POST: add a new hero to the database */
  postLogin(login: Login): Observable<Login> {
    return (
      this.http
        .post<Login>(api.baseUrl + api.user.login, login, httpOptions)
        //  .pipe(catchError(this.handleError("postLogin", login)));
        .pipe(catchError(this.handleError("addHero", login)))
    );
  }

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("LoginService");
  }
}
