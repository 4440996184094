import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { ResponseList, ResponseDelete } from "./response-list";
import { ResponseListService } from "./response-list.service";
import { DashboardCounts } from "./../../dashboard/dashboard";
import { DashboardService } from "./../../dashboard/dashboard.service";

declare let swal: any;

@Component({
  selector: 'app-response-list',
  templateUrl: './response-list.component.html',
  styleUrls: ['./response-list.component.css']
})
export class ResponseListComponent implements OnInit {

  responseList: ResponseList[] = [];
  dashboardDetails: [];
  dashboardCounts: any;
  responseDelete = new ResponseDelete("", "", "");
  responseDetails: any;
  totalResponses: number;

  constructor(
    public navbarService: NavbarService,
    private responseListService: ResponseListService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private dashboardService: DashboardService
  ) {
    this.sidebarService.show();
    this.responseDelete = new ResponseDelete(
      "Delete ",
      "Are you sure want to delete this response?",
      "Response deleted"
    );
    this.responseDetails = {
      user_id: "5c802408c6a0e0136468f5ff",
      status: "active"
    }
    this.totalResponses = 0;
  }

  ngOnInit() {
    this.loaderService.show();
    this.navbarService.show();
    this.fnGetResponseList();
    this.fnDashboardCounts();
  }

  fnGetResponseList(): void {
    this.responseListService.getResponseList(this.responseDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.responseList = response.data.sort((a, b) => {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        });
        this.responseList.forEach(element => {
          this.totalResponses = this.totalResponses + element["count"]
        });
        this.loaderService.hide();
      }
    });
  }

  fnDashboardCounts(): void {
    this.dashboardService.getDashboardCount(this.dashboardDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.dashboardCounts = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnGotoView(routParams: any, view: string): void {
    this.loaderService.show();
    if (view == 'response') {
      this.router.navigate(["/view-response", { "id": routParams._id }]);
    } else if (view == 'survey') {
      this.router.navigate(["/create-survey", { "_id": routParams, "type": "edit" }]);
    }
  }
}
