import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgForm } from "@angular/forms";

import { NavbarService } from "./../navbar/navbar.service";
import { SidebarService } from "./../common/sidebar/sidebar.service";
import { LoaderService } from "./../common/loader/loader.service";

import { Login, LoginValues } from "./login";
import { LoginService } from "./login.service";

declare let swal: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent {
  /*  */
  loginParams: Login[];
  login = new LoginValues("", "");
  submitted = false;

  constructor(
    public navbarService: NavbarService,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private loginService: LoginService,
    private router: Router
  ) {
    this.login = new LoginValues("", "");
    localStorage.setItem('currentView', "");
  }

  ngOnInit() {
    this.loaderService.show();
    this.navbarService.hide();
    this.sidebarService.hide();
    this.loaderService.hide();
  }

  onSubmit(f: NgForm) {
    this.loaderService.show();
    this.submitted = true;
    if (f.valid) {
      this.login = { email: f.value.email, password: f.value.password };
      this.submitted = false;
      this.fnPostLogin();
    } else {
      this.loaderService.hide();
    }
  }

  fnPostLogin(): void {
    this.loaderService.show();
    const newLoginParams: Login = {
      email: this.login.email,
      password: this.login.password
    } as Login;
    this.loginService.postLogin(newLoginParams).subscribe((response: any) => {
      if (response.code == 200) {
        this.loaderService.show();
        this.router.navigate(["/dashboard"]);
        // this.router.navigateByUrl('/red-pill;x=white-rabbit/neo');
      } else {
        this.loaderService.hide();
      }
    });
  }
}
