import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { CourseList, CourseDelete } from "./course-list";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable({
  providedIn: "root"
})
export class CourseListService {
  /*  */
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("courseListService");
  }

  /** GET heroes from the server */
  getCourseList(courseDetails: any): Observable<CourseList[]> {
    return this.http
      .post<CourseList[]>(api.baseUrl + api.course.getCourseListAll, courseDetails)
      .pipe(catchError(this.handleError("getCourseListAll", [])));
  }

  deleteCourse(courseDelete: CourseList): Observable<CourseList> {
    return this.http
      .post<CourseList>(
        api.baseUrl + api.course.deleteCourse,
        { _id: courseDelete._id },
        httpOptions
      )
      .pipe(catchError(this.handleError("updateHero", courseDelete)));

    // return this.http.put(api.baseUrl + popValues.api, { _id: popValues._id });
  }
}
