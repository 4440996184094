import { Component, OnInit, AfterViewInit, ElementRef } from "@angular/core";

import { PopupService } from "./popup.service";
import { DeletePopup } from "./popup";

import { api } from "../../environment/environment.api";

declare let swal: any;

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.css"]
})
export class PopupComponent implements OnInit {
  deletePopup: DeletePopup[];
  constructor(
    public popupService: PopupService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {}

  private showDeletePopup(): void {
    /* swal(
      {
        title: "Test Ajax request example",
        text: "Test Submit to run ajax request",
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function() {
        setTimeout(function() {
          swal("Ajax request finished!");
        }, 2000);
      }
    ); */
    const deletePopup: DeletePopup = {
      title: "Delete ",
      text: "Are you sure want to delete this survey?",
      response: "Survey deleted",
      api: api.survey.deleteSurvey,
      _id: "5c936bcac8a4de34c49c4e2f"
    } as DeletePopup;
    this.popupService.showDeletePopup(deletePopup);
    /*      .subscribe((response: any) => {
        this.elementRef.nativeElement
          .querySelector("confirm")
          .addEventListener("click", this.onClick.bind(this));
      }); */
  }

  ngAfterViewInit() {}

  onClick(event) {}
}
