import { Component, OnInit } from "@angular/core";

import { NavbarService } from "./../navbar/navbar.service";
import { LoaderService } from "./../common/loader/loader.service";

declare let swal: any;

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"]
})
export class LandingComponent implements OnInit {
  constructor(public navbarService: NavbarService, public loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.hide();
    this.navbarService.hide();
  }
}
