import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { NavbarService } from "./../navbar/navbar.service";
import { SidebarService } from "./../common/sidebar/sidebar.service";
import { LoaderService } from "./../common/loader/loader.service";


import { DashboardList, DashboardCounts } from "./dashboard";
import { DashboardService } from "./dashboard.service";

declare let swal: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  /*  */
  dashboardDetails: [];
  dashboardCounts: DashboardCounts[] = [];
  activeAtendance = [];
  staffList: any = {
    "pmanager": 0,
    "cordinator": 0,
    "trainer": 0
  }
  latestSurvey: any;

  constructor(
    public navbarService: NavbarService,
    private dashboardService: DashboardService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
  ) {
    this.loaderService.show();
    this.navbarService.show();
    this.sidebarService.show();
  }

  fnGetResponseList(): void {
    this.dashboardService.getDashboardCount(this.dashboardDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.dashboardCounts = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnGetActiveClasses(): void {
    this.dashboardService.getActiveClasses(this.dashboardDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.activeAtendance = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnGetStaffList(): void {
    this.dashboardService.getDashboardStaffCount(this.dashboardDetails).subscribe((response: any) => {
      if (response.code == 200) {

        response.data.forEach(element => {
          if (element["staff_type"] == 'pmanager') {
            this.staffList.pmanager++;
          } else if (element["staff_type"] == 'cordinator') {
            this.staffList.cordinator++;
          } else if (element["staff_type"] == 'trainer') {
            this.staffList.trainer++;
          }
        });

        this.loaderService.hide();
      }
    });
  }

  fnGetLatestSurvey(): void {
    this.dashboardService.getLatestSurvey(this.dashboardDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.latestSurvey = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnGotoView(routParams: any, view: string, type: string): void {
    this.loaderService.show();

    if (view == "attendance") {
      this.router.navigate(["/view-attendance", { "id": routParams._id }]);
    } else if (view == "staff") {
      this.router.navigate(["/staff", { "id": routParams._id }]);
    } else if (view == "survey") {
      this.router.navigate(["/view-response", { "id": routParams }]);
    } else {
      localStorage.setItem('currentView', routParams);
      this.router.navigate([routParams, {}]);
    }
  }

  ngOnInit() {
    this.fnGetResponseList();
    this.fnGetActiveClasses();
    this.fnGetStaffList();
    this.fnGetLatestSurvey();
  }
}
