import { Component, OnInit } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute, ParamMap } from "@angular/router";
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

import { ChartOptions, ChartType, ChartDataSets, RadialChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MultiDataSet, Label, SingleDataSet, Color } from 'ng2-charts';

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { ResponseDetails } from "./view-response";
import { ViewResponseService } from "./view-response.service";
import { element } from '@angular/core/src/render3';


declare let swal: any;

@Component({
  selector: 'app-view-response',
  templateUrl: './view-response.component.html',
  styleUrls: ['./view-response.component.css']
})
export class ViewResponseComponent implements OnInit {
  responseRouteParams: any;
  responseList: any;
  onLoadFlag: boolean;
  tableQuestionList: any;
  chartQuestionList: any;
  fullPagePrintFlag: boolean = false;
  chartType: any;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    legend: {
      position: 'bottom',
    },
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end'
      },
    }
  };
  //=============================
  // Radar
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };
  //===============================
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          if (ctx.dataset.data[ctx.dataIndex] > 0)
            return label + " : " + ctx.dataset.data[ctx.dataIndex];
          else
            return ""
        },
      }
    },
    showLines: true,
    spanGaps: true,
    cutoutPercentage: 1,
    rotation: 15,

  };
  //=========================

  // PolarArea
  public polarAreaOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  //===============================

  constructor(
    public navbarService: NavbarService,
    private viewResponseService: ViewResponseService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private exportAsService: ExportAsService,
    private route: ActivatedRoute
  ) {
    this.loaderService.show();
    this.navbarService.show();
    this.sidebarService.show();
    this.onLoadFlag = false;
    this.chartType = {
      "smiley": "pie"
    }
  }

  printView(): void {
    this.navbarService.hide();
    this.sidebarService.hide();
    this.fullPagePrintFlag = true;
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
      setTimeout(() => {
        window.print();
        this.loaderService.show();
        setTimeout(() => {
          this.loaderService.show();
          this.navbarService.show();
          this.sidebarService.show();
          this.fullPagePrintFlag = false;
          setTimeout(() => {
            this.loaderService.hide();
          }, 2000);
        }, 1000);
      }, 1000);
    }, 5000);
  }

  fnGetResponseDetails(params: any): void {
    this.loaderService.show();
    this.viewResponseService.getResponseDetails(params).subscribe((response: any) => {
      if (response.code == 200) {
        this.loaderService.show();
        this.responseList = response.data;/* .sort((a, b) => {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        }); */

        let tempTableQuestionList = [];
        this.responseList.details.questions[0].questions.forEach((element1, index1) => {
          tempTableQuestionList.push({ "question": [] });
          this.responseList.details.questions.forEach((element2, index2) => {
            tempTableQuestionList[index1].question.push(element2.questions[index1].title);
          });
        });


        /*   for (let i = 0; i < this.responseList.details.questions[0].length; i++) {
            tempTableQuestionList.push({ "question": [] });
            this.responseList.details.questions.forEach((element2, index2) => {
   
            });
          } */

        let tempQuestionChart = [];
        this.loaderService.show();

        this.responseList.details.questions[0].questions.forEach((element, index) => {
          tempQuestionChart.push({
            "question_title": element.title,
            "question_type": element.question_type,
            "question_id": element._id,
            "answers": [],
            "fields": [],
            "datas": [
              { "data": [], label: '' }],
            "charts": []
          })
          if (element.question_type == 102 || element.question_type == 104) {
            tempQuestionChart[index]["answers"]["1"] = 0;
            tempQuestionChart[index]["answers"]["2"] = 0;
            tempQuestionChart[index]["answers"]["3"] = 0;
            tempQuestionChart[index]["answers"]["4"] = 0;
            tempQuestionChart[index]["answers"]["5"] = 0;
          } else if (element.question_type == 103 || element.question_type == 105) {
            tempQuestionChart[index]["answers"] = [];
            element.fields.forEach(element2 => {
              tempQuestionChart[index]["answers"][element2._id] = [];
              tempQuestionChart[index]["answers"][element2._id]["1"] = 0;
              tempQuestionChart[index]["answers"][element2._id]["2"] = 0;
              tempQuestionChart[index]["answers"][element2._id]["3"] = 0;
              tempQuestionChart[index]["answers"][element2._id]["4"] = 0;
              tempQuestionChart[index]["answers"][element2._id]["5"] = 0;
              tempQuestionChart[index]["answers"][element2._id].shift();
            });
          } else if (element.question_type == 109 || element.question_type == 114) {
            tempQuestionChart[index]["answers"] = [];
            element.fields.forEach(element2 => {
              tempQuestionChart[index]["answers"][element2._id] = 0;
            });
          } else if (element.question_type == 107) {
            tempQuestionChart[index]["answers"]["yes"] = 0;
            tempQuestionChart[index]["answers"]["no"] = 0;
          } else if (element.question_type == 108) {
            tempQuestionChart[index]["answers"]["1"] = 0;
            tempQuestionChart[index]["answers"]["2"] = 0;
            tempQuestionChart[index]["answers"]["3"] = 0;
            tempQuestionChart[index]["answers"]["4"] = 0;
            tempQuestionChart[index]["answers"]["5"] = 0;
            tempQuestionChart[index]["answers"]["6"] = 0;
            tempQuestionChart[index]["answers"]["7"] = 0;
            tempQuestionChart[index]["answers"]["8"] = 0;
            tempQuestionChart[index]["answers"]["9"] = 0;
            tempQuestionChart[index]["answers"]["10"] = 0;
          } else if (element.question_type == 102 || element.question_type == 104) {
            tempQuestionChart[index]["answers"]["1"] = 0;
            tempQuestionChart[index]["answers"]["2"] = 0;
            tempQuestionChart[index]["answers"]["3"] = 0;
            tempQuestionChart[index]["answers"]["4"] = 0;
            tempQuestionChart[index]["answers"]["5"] = 0;
          }

        });
        this.responseList.responses.forEach(element1 => {
          element1.answers.forEach(element2 => {
            let quesChartIdnex = tempQuestionChart.findIndex(item => item.question_id == element2.question_id);
            if (element2.question_type == 107 || element2.question_type == 108 || element2.question_type == 102 || element2.question_type == 104) {
              tempQuestionChart[quesChartIdnex]["answers"][element2.answer[0]] = tempQuestionChart[quesChartIdnex]["answers"][element2.answer[0]] + 1;
            } else if (element2.question_type == 103 || element2.question_type == 105) {
              tempQuestionChart[quesChartIdnex]["answers"][element2.answer[0]] = tempQuestionChart[quesChartIdnex]["answers"][element2.answer[0]] + 1;
              element2.answer.forEach(element2 => {
                tempQuestionChart[quesChartIdnex]["answers"][element2.question_id][element2.answer - 1] = tempQuestionChart[quesChartIdnex]["answers"][element2.question_id][element2.answer - 1] + 1;
              });
            } else if (element2.question_type == 109 || element2.question_type == 114) {
              tempQuestionChart[quesChartIdnex]["answers"][element2.answer[0]] = tempQuestionChart[quesChartIdnex]["answers"][element2.answer[0]] + 1;
            }
          });
        });
        this.loaderService.show();

        let smileIndex = 0;
        tempQuestionChart.forEach((element, index) => {
          if (element.question_type == 104) {
            smileIndex++;
            element["answers"].shift();

            element["charts"].push(
              {
                "labels": ["Very Sad", "Sad", "Neutral", "Happy", "Very Happy"],
                "data": element["answers"],
                "type": 'pie',
                "legend": true,
                "plugins": [pluginDataLabels]
              }
            )
            element["charts"].push(
              {
                "labels": ["Very Sad", "Sad", "Neutral", "Happy", "Very Happy"],
                "data": element["answers"],
                "type": 'polarArea',
                "legend": true,
              }
            )
            element["charts"].push(
              {
                "labels": ["Very Sad", "Sad", "Neutral", "Happy", "Very Happy"],
                "data": [
                  { data: element["answers"], label: 'Rating' }
                ],
                "type": 'bar',
                "legend": true,
                "plugins": [pluginDataLabels]
              }
            )

            /*     if (smileIndex % 3 == 0) {
                  //Pie
                  element["labels"] = ["Very Sad", "Sad", "Neutral", "Happy", "Very Happy"];
                  element["data"] = element["answers"];
                  element["type"] = 'pie';
                  element["legend"] = true;
                  element["plugins"] = [pluginDataLabels];
                } else if (smileIndex % 2 == 0) {
                  // PolarArea
                  element["labels"] = ["Very Sad", "Sad", "Neutral", "Happy", "Very Happy"];
                  element["data"] = element["answers"];
                  element["legend"] = true;
                  element["type"] = 'polarArea';
                } else if (smileIndex % 1 == 0) {
                  // Bar
                  element["labels"] = ["Very Sad", "Sad", "Neutral", "Happy", "Very Happy"];
                  element["type"] = 'bar';
                  element["legend"] = true;
                  element["plugins"] = [pluginDataLabels];
                  element["data"] = [
                    { data: element["answers"], label: 'Rating' }
                  ];
                } */

          }
          else if (element.question_type == 108) {
            //Bar
            element["labels"] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
            element["type"] = 'bar';
            element["legend"] = true;
            element["plugins"] = [pluginDataLabels];
            element["data"] = [
              { data: element["answers"], label: 'Rating' }
            ];
          } else if (element.question_type == 107) {
            //Pie
            element["labels"] = [['Yes'], ['No']];
            element["data"] = [element["answers"].yes, element["answers"].no];
            element["type"] = 'pie';
            element["legend"] = true;
            element["plugins"] = [pluginDataLabels];
            element["colors"] = [
              {
                backgroundColor: ['rgba(0,255,0,0.3)', 'rgba(255,0,0,0.3)'],
              },
            ];
          } else if (element.question_type == 102) {
            // PolarArea
            element["labels"] = ["1", "2", "3", "4", "5"];
            element["data"] = element["answers"];
            element["legend"] = true;
            element["type"] = 'polarArea';
          } else if (element.question_type == 103 || element.question_type == 105) {
            // Bar Multi
            element["labels"] = ["1", "2", "3", "4", "5"];
            element["type"] = 'bar';
            element["legend"] = true;
            element["plugins"] = [pluginDataLabels];
            let tempdata = [];
            let tempQueIndex = this.responseList.details.questions[0].questions.findIndex(item => item._id == element.question_id);
            this.responseList.details.questions[0].questions[tempQueIndex].fields.forEach(element2 => {
              tempdata.push({
                "data": element.answers[element2._id],
                "label": element2.title
              })
            });
            element["data"] = tempdata;
          } else if (element.question_type == 109 || element.question_type == 114) {

            element["legend"] = true;
            element["type"] = 'polarArea';
            let tempData = [];
            let tempLabel = [];
            let tempQueIndex = this.responseList.details.questions[0].questions.findIndex(item => item._id == element.question_id);
            this.responseList.details.questions[0].questions[tempQueIndex].fields.forEach(element2 => {
              tempData.push(element.answers[element2._id]);
              tempLabel.push(element2.title);
            });
            element["label"] = tempLabel;
            element["data"] = tempData;
          }
        });
        this.loaderService.show();
        this.tableQuestionList = tempTableQuestionList;
        this.chartQuestionList = tempQuestionChart;
        this.onLoadFlag = true;
        this.loaderService.hide();
        setTimeout(() => {
          this.loaderService.hide();
        }, 5000);
      }
    });
  }

  showHideLoader(): void {
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 5000);
  }

  ngOnInit() {
    this.loaderService.show();
    this.route.paramMap.subscribe(params => {
      this.responseRouteParams = params["params"];
      this.fnGetResponseDetails(this.responseRouteParams);
    });
  }
}
