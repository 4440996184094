import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { AttendanceList, AttendanceDelete } from "./attendance-list";
import { AttendanceListService } from "./attendance-list.service";
import { DashboardService } from "./../../dashboard/dashboard.service";

declare let swal: any;

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.css']
})
export class AttendanceListComponent implements OnInit {
  attendanceList: AttendanceList[] = [];
  attendanceDelete = new AttendanceDelete("", "", "");
  activeAtendance: any;
  flagActiveAttendance: boolean;

  constructor(
    public navbarService: NavbarService,
    private attendanceListService: AttendanceListService,
    private dashboardService: DashboardService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
  ) {
    this.sidebarService.show();
    this.attendanceDelete = new AttendanceDelete(
      "Delete ",
      "Are you sure want to delete this attendance?",
      "Attendance deleted"
    );
    this.flagActiveAttendance = false;
  }

  ngOnInit() {
    this.loaderService.show();
    this.navbarService.show();
    this.fnGetAttendanceList();
    this.fnGetActiveClasses();
  }

  fnGetAttendanceList(): void {
    this.attendanceListService.getAttendanceList().subscribe((response: any) => {
      if (response.code == 200) {
        this.attendanceList = response.data.sort((a, b) => {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        });
        this.loaderService.hide();
      }
    });
  }

  fnGetActiveClasses(): void {
    this.loaderService.show();
    this.dashboardService.getActiveClasses(this.activeAtendance).subscribe((response: any) => {
      if (response.code == 200) {
        this.activeAtendance = response.data;
        this.flagActiveAttendance = true;
        this.loaderService.hide();
      }
    });
  }

  fnDeleteAttendance(attendanceId): void {
    var moment = this;
    const tempAttendanceId: AttendanceList = {
      _id: attendanceId
    } as AttendanceList;
    swal(
      {
        title: this.attendanceDelete.title,
        text: this.attendanceDelete.text,
        response: this.attendanceDelete.response,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function () {
        moment.fnDeleteAttendanceCall(tempAttendanceId);
      }
    );
  }

  fnDeleteAttendanceCall(tempAttendanceId): void {
    this.attendanceListService
      .deleteAttendance(tempAttendanceId)
      .subscribe((response: any) => {
        this.fnGetAttendanceList();
        swal(this.attendanceDelete.response);
      });
  }

  fnGotoView(routParams: any, view: string, type: string): void {
    this.loaderService.show();
    if (view == "attendance") {
      view = "/view-attendance";
    } if (view == 'class') {
      view = 'create-class';
    } else if (view == 'course') {
      view = 'create-course';
    }
    let tempRouteParams: any = {
      "id": routParams._id, "type": type
    };
    this.router.navigate([view, tempRouteParams]);
  }
}
