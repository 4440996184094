import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { StaffsRouteParams, StaffsDetails } from "./create-staffs";
import { CreateStaffsService } from "./create-staffs.service";

declare let swal: any;

@Component({
  selector: "app-create-staffs",
  templateUrl: "./create-staffs.component.html",
  styleUrls: ["./create-staffs.component.css"]
})
export class CreateStaffsComponent implements OnInit {
  // staffsRouteParams: StaffsRouteParams;
  staffsDetails: StaffsDetails;
  staffsRouteParams: any;
  staffTypes: any;

  constructor(
    private router: Router, private route: ActivatedRoute,
    private createStaffsService: CreateStaffsService,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
  ) {
    this.sidebarService.show();
    this.sidebarService.show();
    this.route.paramMap.subscribe(params => {
      this.staffsRouteParams = params["params"];
    });
    if (this.staffsRouteParams.length <= 0) {
      this.staffsRouteParams = {
        "type": "create"
      }
    }

    this.staffsDetails = {
      _id: "",
      staff_name: "",
      staff_type: "",
      user_id: "",
      created_date: new Date(),
      status: "",
      type: this.staffsRouteParams.type,
      staffs_description: ""
    };
  }

  fnGetStaffsById(staffsId: string): void {
    this.loaderService.show();
    let tempParams = {
      "user_id": "test",
      "_id": staffsId
    }

    this.createStaffsService
      .getStaffsById(tempParams)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.staffsDetails = response.data;
        }
        this.loaderService.hide();
      });
  }

  fnGetStaffsTypes(): void {
    this.createStaffsService.getStaffsTypes({
      category: "staff"
    }).subscribe((response: any) => {
      if (response.code == 200) {
        this.staffTypes = response.data;
      }
      this.loaderService.hide();
    });
  }

  fnSaveStaffs(): void {
    this.loaderService.show();
    this.staffsDetails.type = this.staffsRouteParams.type;
    if (this.staffsRouteParams.type == "edit") {
      this.createStaffsService
        .updateStaffs(this.staffsDetails)
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.staffsDetails = response.data;
          }
          this.loaderService.hide();
          swal("Staff Updated!", "", "success");
        });
    } else {
      this.createStaffsService
        .createStaffs(this.staffsDetails)
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.staffsDetails = response.data;
            this.staffsDetails.type = "edit";
            this.staffsRouteParams = {
              _id: this.staffsDetails._id,
              type: "edit"
            }
          }
          this.loaderService.hide();
          swal("Staff Created!", "", "success");
        });
    }
  }

  fnCancelStaffs(): void {

  }

  ngOnInit() {
    this.loaderService.show();
    /* this.staffsRouteParams = {
      _id: "5ca0db674993bd3020e2bde5",
      staff_name: "Test Staffs 12",
      user_id: "5c802408c6a0e0136468f5ff",
      created_date: new Date("2019-03-22T21:40:41.016Z"),
      totalQuestions: 2,
      type: "edit"
    }; */
    this.fnGetStaffsTypes();
    if (this.staffsRouteParams.type == "edit") {
      this.fnGetStaffsById(this.staffsRouteParams._id);
    }
  }
}
