import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import {
  HttpErrorHandler,
  HandleError
} from "./../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "./../../environment/environment.httpHeader";

import { DeletePopup } from "./popup";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable()
export class PopupService {
  showDeletePopup(popValues: DeletePopup) {
    /* var rootObj = this;
     swal(
      {
        title: popValues.title,
        text: popValues.text,
        response: popValues.response,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function() {
        rootObj.deleteFunction(popValues).subscribe(response => {
          if (response.code == 200) {
            swal(popValues.response);
          }
        });
        setTimeout(function() {}, 2000);
      }
    ); */
  }

  deleteFunction(popValues: DeletePopup): Observable<DeletePopup> {
    return this.http
      .put<DeletePopup>(
        api.baseUrl + popValues.api,
        { _id: popValues._id },
        httpOptions
      )
      .pipe(catchError(this.handleError("updateHero", popValues)));

    // return this.http.put(api.baseUrl + popValues.api, { _id: popValues._id });
  }

  private handleError: HandleError;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("LoginService");
  }
}
