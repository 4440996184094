import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LandingComponent } from "./landing/landing.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
/* Survey */
import { SurveyListComponent } from "./survey/survey-list/survey-list.component";
import { CreateSurveyComponent } from "./survey/create-survey/create-survey.component";
/* Course */
import { CourseListComponent } from "./course/course-list/course-list.component";
import { CreateCourseComponent } from "./course/create-course/create-course.component";
/* Class */
import { ClassListComponent } from "./class/class-list/class-list.component";
import { CreateClassComponent } from "./class/create-class/create-class.component";
/* Staff */
import { StaffsListComponent } from "./staffs/staffs-list/staffs-list.component";
import { CreateStaffsComponent } from "./staffs/create-staffs/create-staffs.component";
/* Attendance */
import { AttendanceListComponent } from "./attendance/attendance-list/attendance-list.component";
import { ViewAttendanceComponent } from "./attendance/view-attendance/view-attendance.component";
import { AddAttendanceComponent } from "./attendance/add-attendance/add-attendance.component";

/* Responses */
import { ResponseListComponent } from "./response/response-list/response-list.component"
import { ViewResponseComponent } from "./response/view-response/view-response.component"

const routes: Routes = [
  /*  { path: "", component: LandingComponent }, */
  { path: "", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "survey", component: SurveyListComponent },
  { path: "create-survey", component: CreateSurveyComponent },
  { path: "course", component: CourseListComponent },
  { path: "create-course", component: CreateCourseComponent },
  { path: "class", component: ClassListComponent },
  { path: "create-class", component: CreateClassComponent },
  { path: "staff", component: StaffsListComponent },
  { path: "create-staffs", component: CreateStaffsComponent },
  { path: "attendance", component: AttendanceListComponent },
  { path: "view-attendance", component: ViewAttendanceComponent },
  { path: "add-attendance", component: AddAttendanceComponent },
  { path: "response", component: ResponseListComponent },
  { path: "view-response", component: ViewResponseComponent },
  {
    path: '**',
    component: DashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
