import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  Router,
  NavigationExtras,
  ActivatedRoute,
  ParamMap
} from "@angular/router";

import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { ClassRouteParams, ClassDetails } from "./create-class";
import { CreateClassService } from "./create-class.service";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

type AOA = any[][];

declare let swal: any;

@Component({
  selector: "app-create-class",
  templateUrl: "./create-class.component.html",
  styleUrls: ["./create-class.component.css"]
})
export class CreateClassComponent implements OnInit {
  @ViewChild('asd') table: ElementRef;

  // classRouteParams: ClassRouteParams;
  classDetails: ClassDetails;
  classRouteParams: any;
  staffTypes: any;
  courseList: any;
  staffList: any;

  constructor(
    private router: Router,
    private createClassService: CreateClassService,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private route: ActivatedRoute
  ) {
    this.sidebarService.show();
    const navigation = this.router.getCurrentNavigation();
    this.route.paramMap.subscribe(params => {
      this.classRouteParams = params["params"];
    });
    /* if (navigation.previousNavigation != null) {
      localStorage.setItem("storedClassDetails", JSON.stringify(this.classRouteParams))
    } else {
      this.classRouteParams = JSON.parse(localStorage.getItem("storedClassDetails"));
    }
 */
    if (this.classRouteParams["type"] == '') {
      this.classRouteParams.type = "create";
    }

    this.classDetails = {
      _id: "",
      class_id: "",
      class_name: "",
      client_name: "",
      class_pin: "",
      class_from_date: new Date(),
      class_to_date: new Date(),
      class_description: "",
      course_id: "",
      trainer_id: "",
      cordinator_id: "",
      pmanager_id: "",
      created_date: new Date(),
      status: "",
      type: this.classRouteParams.type,
      student_details: [{
        name_en: "",
        name_ar: "",
        national_id: "",
        mail_id: "",
        mobile_number: ""
      }]
    };
  }

  fnGetClassById(classId: string): void {
    this.loaderService.show();
    this.createClassService
      .getClassById(classId)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.classDetails = response.data;
          this.loaderService.hide();
        }
      });
  }

  /*   fnGetClassTypes(): void {
      this.createClassService.getClassTypes({
        category: "staff"
      }).subscribe((response: any) => {
        if (response.code == 200) {
          this.staffTypes = response.data;
        }
      });
    } */

  fnSaveClass(): void {
    this.loaderService.show();
    this.classDetails.type = this.classRouteParams.type;
    if (this.classRouteParams.type == "edit") {
      this.createClassService
        .updateClass(this.classDetails)
        .subscribe((response: any) => {
          if (response.code == 200) {
            //this.classDetails = response.data;
            this.fnGetClassById(response.data._id);
          }
        });
      this.loaderService.hide();
      swal("Class Updated!", "", "success");
    } else {
      this.createClassService
        .createClass(this.classDetails)
        .subscribe((response: any) => {
          if (response.code == 200) {
            //this.classDetails = response.data;
            this.classDetails.type = "edit";
            this.classRouteParams = {
              "_id": this.classDetails._id,
              "type": "edit"
            }
            this.fnGetClassById(response.data._id);
          }
        });
      this.loaderService.hide();
      swal("Class Created!", "", "success");
    }
  }

  fnAddStudent() {
    this.classDetails.student_details.push({
      name_en: "",
      name_ar: "",
      national_id: "",
      mail_id: "",
      mobile_number: ""
    });
  }

  fnGetClassTypes(): void {
    this.createClassService.getCourseList({
      user_id: "staff"
    }).subscribe((response: any) => {
      if (response.code == 200) {
        this.courseList = response.data;
        this.loaderService.hide();
      }
    });
  }

  filterTrainer = { staff_type: 'trainer' };
  filterCordinator = { staff_type: 'cordinator' };
  filterPManager = { staff_type: 'pmanager' };


  fnGetStaffsTypes(): void {
    this.createClassService.getStaffsList({
      user_id: "staff"
    }).subscribe((response: any) => {
      if (response.code == 200) {
        this.staffList = response.data;
        this.loaderService.hide();
      }
    });
  }

  fnCancelClass(): void {

  }
  data: any;
  /* <input type="file" (change)="onFileChange($event)" multiple="false" /> */
  /* ... (within the component class definition) ... */
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      let tempSheetData: any = [];
      delete this.data[0];
      this.data.forEach(element => {
        tempSheetData.push({
          name_ar: element[1] ? element[1] : "",
          name_en: element[2] ? element[2] : "",
          mail_id: element[3] ? element[3] : "",
          national_id: element[4] ? element[4] : "",
          mobile_number: element[5] ? element[5] : ""
        })
      });
      this.classDetails.student_details = tempSheetData;
    };
    reader.readAsBinaryString(target.files[0]);
  }

  ngOnInit() {
    this.loaderService.show();
    /* this.classRouteParams = {
      _id: "5ca0db674993bd3020e2bde5",
      staff_name: "Test Class 12",
      user_id: "5c802408c6a0e0136468f5ff",
      created_date: new Date("2019-03-22T21:40:41.016Z"),
      totalQuestions: 2,
      type: "edit"
    }; */
    this.fnGetClassTypes();
    this.fnGetStaffsTypes();
    //this.fnGetClassTypes();
    if (this.classRouteParams.type == "edit") {
      this.fnGetClassById(this.classRouteParams._id);
    }
  }

  ngOnDestroy() {
    localStorage.removeItem("storedClassDetails");
  }


  exportStudentList(): void {
    let myJSON = this.classDetails.student_details;
    myJSON.forEach((element, index) => {
      index++;
      delete element["_id"];
      element["Sl No."] = index;
    });
    var newMyJSON = JSON.stringify(myJSON);
    newMyJSON = newMyJSON.replace(/name_ar/g, "Name - Arabic");
    newMyJSON = newMyJSON.replace(/name_en/g, "Name - English");
    newMyJSON = newMyJSON.replace(/mail_id/g, "Email");
    newMyJSON = newMyJSON.replace(/national_id/g, "National Id");
    newMyJSON = newMyJSON.replace(/mobile_number/g, "Mobile Number");
    myJSON = JSON.parse(newMyJSON);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(myJSON, { header: ["Sl No.", "Name - Arabic", "Name - English", "Email", "National Id", "Mobile Number"] });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'test');
    XLSX.writeFile(wb, this.classDetails.class_name + '.xlsx');
  }
}
