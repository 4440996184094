export interface CourseList {
  _id: string;
  survey_name: string;
}

export class CourseDelete {
  constructor(
    public title: string,
    public text: string,
    public response: string
  ) {}
}
