import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { SidebarService } from "./sidebar.service";

declare let swal: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  currentView: string;
  constructor(public sidebarService: SidebarService,
    private router: Router) {
  }

  fnGotoView(routLink: string): void {
    this.currentView = routLink;
    localStorage.setItem('currentView', this.currentView);
    this.router.navigate([routLink, {}]);
  }

  ngOnInit() {
    this.currentView = localStorage.getItem('currentView')
    if (this.currentView == undefined || this.currentView == "") {
      this.currentView = "dashboard"
    }
    localStorage.setItem('currentView', this.currentView);
  }
}
