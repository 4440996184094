import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipes'
})
export class FilterPipe implements PipeTransform {


  transform(staffList: any[], filter: Object): any {
    if (!staffList || !filter) {
      return staffList;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return staffList.filter(staff => staff.staff_type.indexOf(filter["staff_type"]) !== -1);
  }
}

