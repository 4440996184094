import { Component, OnInit } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute, ParamMap } from "@angular/router";
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { AttendanceDetails, AttendanceDelete } from "./view-attendance";
import { ViewAttendanceService } from "./view-attendance.service";

declare let swal: any;

@Component({
  selector: 'view-attendance',
  templateUrl: './view-attendance.component.html',
  styleUrls: ['./view-attendance.component.css']
})

export class ViewAttendanceComponent implements OnInit {

  attendanceDetails: AttendanceDetails[] = [];
  attendanceDelete = new AttendanceDelete("", "", "");
  attendanceRouteParams: any;
  classDetails: any;
  onLoadFlag = false;
  printType = false;

  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementId: 'reportDiv', // the id of html/table element
  }

  constructor(
    public navbarService: NavbarService,
    private viewAttendanceService: ViewAttendanceService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private exportAsService: ExportAsService,
    private route: ActivatedRoute
  ) {
    this.sidebarService.show();
    this.attendanceDelete = new AttendanceDelete(
      "Delete ",
      "Are you sure want to delete this attendance?",
      "Attendance deleted"
    );
    this.classDetails = {
      "student_details": []
    }
    const navigation = this.router.getCurrentNavigation();
    this.route.paramMap.subscribe(params => {
      this.attendanceRouteParams = params["params"];
    });

    if (navigation.previousNavigation != null) {
      localStorage.setItem("storedAttendanceDetails", JSON.stringify(this.attendanceRouteParams))
    } else {
      this.attendanceRouteParams = JSON.parse(localStorage.getItem("storedAttendanceDetails"));
    }

  }

  export() {
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, this.classDetails.class_name + '_AttendanceReport');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    /*  this.exportAsService.get(this.exportAsConfig).subscribe(content => {
     });*/
  }

  classStudentCount = 0;
  printPageLength = [];

  fnGetClassDetails(params: any): void {
    this.viewAttendanceService.getClassDetails(params).subscribe((response: any) => {
      if (response.code == 200) {
        this.classDetails = response.data;
        this.classStudentCount
        let a = this.classDetails.student_details.length;
        let b = (a / 20).toString();
        let c = parseInt(b);
        if ((a / 20) % 1 > 0) {
          if (a > 20) {
            c = c + 1;
          } else {
            c = 1;
          }
        }
        let i = 1;
        do {
          this.printPageLength.push(i);
          i++;
        } while (this.printPageLength.length < c);
      }
    });
  }

  fnGetAttendanceDetails(params: any): void {
    this.viewAttendanceService.getAttendanceDetails(params).subscribe((response: any) => {
      if (response.code == 200) {
        this.attendanceDetails = response.data;
        this.onLoadFlag = true;
        this.loaderService.hide();
      }
    });
  }

  fnDeleteAttendance(attendanceId): void {/* 
    var moment = this;
    const tempAttendanceId: AttendanceDetails = {
      _id: attendanceId
    }
    as AttendanceDetails;
    swal({
        title: this.attendanceDelete.title,
        text: this.attendanceDelete.text,
        response: this.attendanceDelete.response,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },
      function () {
        moment.fnDeleteAttendanceCall(tempAttendanceId);
      }
    ); */
  }

  fnDeleteAttendanceCall(tempAttendanceId): void {
    this.viewAttendanceService
      .deleteAttendance(tempAttendanceId)
      .subscribe((response: any) => {
        //  this.fnGetAttendanceDetails();
        swal(this.attendanceDelete.response);
      });
  }

  fnGotoView(routParams: any, view: string, type: string): void {
    this.loaderService.show();
    let tempRouteParams: NavigationExtras = {};

    if (view == "attendance") {
      this.router.navigate(["/view-attendance"], tempRouteParams);
    } else if (view == "add") {
      this.router.navigate(["/add-attendance", { id: this.attendanceRouteParams["id"] }]);
    }
  }


  ngOnInit() {
    this.loaderService.show();
    this.navbarService.show();
    this.fnGetClassDetails(this.attendanceRouteParams);
    this.fnGetAttendanceDetails(this.attendanceRouteParams);

  }
}
