import { Component } from "@angular/core";

import { NavbarService } from "./navbar/navbar.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "Survey";

  constructor(public nav: NavbarService) {}

  ngOnInit() {
    this.nav.show();
  }
}
