import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { AttendanceList, AttendanceDelete } from "./attendance-list";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable({
  providedIn: "root"
})
export class AttendanceListService {
  /*  */
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("attendanceListService");
  }

  /** GET heroes from the server */
  getAttendanceList(): Observable<AttendanceList[]> {
    return this.http
      .get<AttendanceList[]>(api.baseUrl + api.class.getClassList)
      .pipe(catchError(this.handleError("getAttendanceList", [])));
  }

  deleteAttendance(attendanceDelete: AttendanceList): Observable<AttendanceList> {
    return this.http
      .put<AttendanceList>(
        api.baseUrl + api.attendance.deleteAttendance,
        { _id: attendanceDelete._id },
        httpOptions
      )
      .pipe(catchError(this.handleError("updateHero", attendanceDelete)));

    // return this.http.put(api.baseUrl + popValues.api, { _id: popValues._id });
  }
}
