import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, ParamMap } from "@angular/router";
import { formatDate } from '@angular/common';

import * as moment from 'moment';

import { NavbarService } from "./../../navbar/navbar.service";
import { SidebarService } from "./../../common/sidebar/sidebar.service";
import { LoaderService } from "./../../common/loader/loader.service";

import { AttendanceDetails, AttendanceDelete } from "./add-attendance";
import { AddAttendanceService } from "./add-attendance.service";

declare let swal: any;

@Component({
  selector: 'app-add-attendance',
  templateUrl: './add-attendance.component.html',
  styleUrls: ['./add-attendance.component.css']
})
export class AddAttendanceComponent implements OnInit {

  attendanceDetails: AttendanceDetails[] = [];
  classDetails: any;
  flag: any;
  selectedDate: Date;
  minDate: any;
  maxDate: any;

  attendanceRouteParams: any;
  onLoadFlag: any;
  constructor(
    public navbarService: NavbarService,
    private addAttendanceService: AddAttendanceService,
    private router: Router,
    public sidebarService: SidebarService,
    public loaderService: LoaderService,
    private route: ActivatedRoute,
  ) {
    this.sidebarService.show();
    this.loaderService.show();
    this.onLoadFlag = false;
    this.flag = {
      saveAttendance: true
    }
    const navigation = this.router.getCurrentNavigation();
    this.route.paramMap.subscribe(params => {
      this.attendanceRouteParams = params["params"];
    });

  }

  fnGetClassDetails(params: any): void {
    this.addAttendanceService.getClassDetails(params).subscribe((response: any) => {
      if (response.code == 200) {
        this.classDetails = response.data;
        this.classDetails["newFromDate"] = formatDate(this.classDetails.class_from_date, 'yyyy-MM-dd', 'en');
        this.classDetails["newToDate"] = formatDate(this.classDetails.class_to_date, 'yyyy-MM-dd', 'en');
        this.minDate = this.classDetails["newFromDate"];
        this.maxDate = this.classDetails["newToDate"];
        this.selectedDate = this.classDetails["newFromDate"];
        this.loaderService.hide();

        this.fnGetAttendanceDetails();
        this.onLoadFlag = true;
      }
    });
  }

  fnGetAttendanceDetails(): void {
    this.flag.saveAttendance = true;
    let tempClassDetails = {
      "user_id": this.classDetails.user_id,
      "class_id": this.classDetails._id,
      "course_id": this.classDetails.course_id,
      "attendance_date": this.selectedDate
    }
    this.loaderService.show();
    this.addAttendanceService.getAttendanceDetails(tempClassDetails).subscribe((response: any) => {
      if (response.code == 200) {
        this.attendanceDetails = response.data || [];
        if (!response.data) {
          //  this.studentDetails = 
          let studentDetails = [];
          this.classDetails.student_details.forEach(element => {
            studentDetails.push({
              student_id: element._id,
              _id: element._id,
              name_ar: element.name_ar,
              name_en: element.name_en,
              national_id: element.national_id,
              mobile_number: element.mobile_number,
              status: null,
              created_date: new Date()
            })
          });
          this.attendanceDetails["attendance_list"] = studentDetails;
          this.flag.saveUpdate = true;
        } else {
          this.classDetails.student_details.forEach((ele1, index) => {
            this.attendanceDetails["attendance_list"].forEach((ele2) => {
              if (ele1._id == ele2["student_id"]) {
                ele2["name_ar"] = ele1.name_ar;
                ele2["name_en"] = ele1.name_en;
              } else if (index > this.attendanceDetails["attendance_list"].length-1) {
                /*      ele2["student_id"] = ele1._id;
                     ele2["name_ar"] = ele1.name_ar;
                     ele2["name_en"] = ele1.name_en;
                     ele2["status"] = null;
                     ele2["created_date"] = new Date(); */

                this.attendanceDetails["attendance_list"].push({
                  "student_id": ele1._id,
                  "name_ar": ele1.name_ar,
                  "name_en": ele1.name_en,
                  "status": null,
                  "created_date": new Date()
                })
              }
            });
          });
          this.flag.saveAttendance = false;
        }
        this.loaderService.hide();
      }
    });
  }

  fnSaveAttendance(): void {
    let tempStudentDetails = {
      "_id": this.attendanceDetails["_id"],
      "class_id": this.classDetails._id,
      "course_id": this.classDetails.course_id,
      "user_id": this.classDetails.user_id,
      "attendance_date": new Date(this.selectedDate).toDateString(),
      "attendance_list": this.attendanceDetails["attendance_list"]
    }
    let tempData: any;
    if (this.flag.saveAttendance) {
      this.addAttendanceService.saveAttendanceDetails(tempStudentDetails).subscribe((response: any) => {
        if (response["code"] == 200) {
          this.flag.saveAttendance = false;
          swal("Attendance Created!", "", "success");
        }
      });
    } else {
      this.addAttendanceService.updateAttendanceDetails(tempStudentDetails).subscribe((response: any) => {
        if (response["code"] == 200) {
          this.flag.saveAttendance = false;
          swal("Attendance Updated!", "", "success");

        }
      });
    }
  }

  fnGotoView(routParams: any, view: string, type: string): void {
    this.loaderService.show();
    if (view == "attendance") {
      view = "/view-attendance";
    }
    let tempRouteParams: any = {
      "id": this.classDetails._id, "type": type
    };
    this.router.navigate([view, tempRouteParams]);
  }

  ngOnInit() {
    this.fnGetClassDetails(this.attendanceRouteParams);
  }

}
