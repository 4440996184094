import { Component, OnInit } from "@angular/core";

import { Config, ConfigService } from "./config.service";

declare let swal: any;

@Component({
  selector: "app-config",
  templateUrl: "./config.component.html",
  styleUrls: ["./config.component.css"],
  providers: [ConfigService]
})
export class ConfigComponent implements OnInit {
  config: Config;
  error: any;
  headers: string[];

  constructor(private configService: ConfigService) {}

  ngOnInit() {}

  showConfig() {
    this.configService.getConfig().subscribe(
      (data: Config) => (
        (this.config = {
          postLogin: data["postLogin"]
        }),
        error => (this.error = error) // error path
      )
    );
  }

  showConfigResponse() {
    this.configService
      .getConfigResponse()
      // resp is of type `HttpResponse<Config>`
      .subscribe(resp => {
        // display its headers
        const keys = resp.headers.keys();
        this.headers = keys.map(key => `${key}: ${resp.headers.get(key)}`);

        // access the body directly, which is typed as `Config`.
        this.config = { ...resp.body };
      });
  }
}
