import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "../../http-error-handler.service";
import { api } from "../../environment/environment.api";
import { httpHeaders } from "../../environment/environment.httpHeader";

import { AttendanceDetails, ClassDetails } from "./add-attendance";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};

@Injectable({
  providedIn: "root"
})
export class AddAttendanceService {
  /*  */
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("AttendanceDetailsService");
  }

  /** GET heroes from the server */
  getAttendanceDetails(params: any): Observable<AttendanceDetails[]> {
    return this.http
      .post<AttendanceDetails[]>(api.baseUrl + api.attendance.getAttendanceByDate, {
        user_id: params.user_id,
        class_id: params.class_id,
        course_id: params.course_id,
        attendance_date: params.attendance_date
      })
      .pipe(catchError(this.handleError("getAttendanceDetails", [])));
  }

  /** GET heroes from the server */
  getClassDetails(params: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.class.getClassById, {
        class_id: params.id,
        user_id: params.user_id
      })
      .pipe(catchError(this.handleError("getClassDetails", [])));
  }

  updateAttendanceDetails(params: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.attendance.updateAttendance, params)
      .pipe(catchError(this.handleError("getClassDetails", [])));
  }
  saveAttendanceDetails(params: any): Observable<ClassDetails[]> {
    return this.http
      .post<ClassDetails[]>(api.baseUrl + api.attendance.saveAttendance, params)
      .pipe(catchError(this.handleError("getClassDetails", [])));
  }

}
