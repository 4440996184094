import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorHandler,
  HandleError
} from "./../http-error-handler.service";
import { api } from "./../environment/environment.api";
import { httpHeaders } from "./../environment/environment.httpHeader";

import { DashboardList } from "./dashboard";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": httpHeaders.ContentType,
    Authorization: httpHeaders.Authorization,
    "Access-Control-Allow-Origin": httpHeaders.AllowOrigin,
    "Access-Control-Allow-Methods": httpHeaders.AllowMethods,
    "Access-Control-Allow-Headers": httpHeaders.AllowHeaders
  })
};
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("DashboardService");
  }

  /** GET heroes from the server */
  getDashboardCount(responseDetails: any): Observable<DashboardList[]> {
    return this.http
      .post<DashboardList[]>(api.baseUrl + api.dashboard.getCounts, responseDetails)
      .pipe(catchError(this.handleError("getDashboardList", [])));
  }
  /** GET heroes from the server */
  getActiveClasses(responseDetails: any): Observable<DashboardList[]> {
    return this.http
      .post<DashboardList[]>(api.baseUrl + api.dashboard.getActiveClasses, responseDetails)
      .pipe(catchError(this.handleError("getActiveClasses", [])));
  }
  getDashboardStaffCount(responseDetails: any): Observable<DashboardList[]> {
    return this.http
      .post<DashboardList[]>(api.baseUrl + api.dashboard.getStaffCounts, responseDetails)
      .pipe(catchError(this.handleError("getDashboardStaffCount", [])));
  }
  getLatestSurvey(responseDetails: any): Observable<DashboardList[]> {
    return this.http
      .post<DashboardList[]>(api.baseUrl + api.dashboard.getLatestSurvey, responseDetails)
      .pipe(catchError(this.handleError("getLatestSurvey", [])));
  }
}
